export const initStickyCard = (id, additionalOffset = 0) => {
  const element = window.KTUtil.getById(id)
  if (!element) return

  let offset = typeof window.KTLayoutHeader !== 'undefined' ? window.KTLayoutHeader.getHeight() : 300

  const object = new window.KTCard(element, {
    sticky: {
      offset: offset,
      zIndex: 90,
      position: {
        top: () => {
          let pos = 0;

          if (window.KTUtil.isBreakpointUp('lg')) {
            if (typeof window.KTLayoutHeader !== 'undefined' && window.KTLayoutHeader.isFixed()) {
              pos = pos + window.KTLayoutHeader.getHeight();
            }

            if (typeof window.KTLayoutSubheader !== 'undefined' && window.KTLayoutSubheader.isFixed()) {
              pos = pos + window.KTLayoutSubheader.getHeight();
            }
          } else {
            if (typeof window.KTLayoutHeader !== 'undefined' && window.KTLayoutHeader.isFixedForMobile()) {
              pos = pos + window.KTLayoutHeader.getHeightForMobile();
            }
          }

          pos = pos - 1; // remove header border width

          return pos + additionalOffset;
        },
        left: (card) => {
          return window.KTUtil.offset(card.element).left;
        },
        right: (card) => {
          const body = window.KTUtil.getBody();

          const cardWidth = parseInt(window.KTUtil.css(card.element, 'width'));
          const bodyWidth = parseInt(window.KTUtil.css(body, 'width'));
          const cardOffsetLeft = window.KTUtil.offset(card.element).left;

          return bodyWidth - cardWidth - cardOffsetLeft;
        }
      }
    }
  })

  object.initSticky()

  window.KTUtil.addResizeHandler(() => { object.updateSticky() })

  return object
}
