import { i18n } from "../i18n";
import { csrfToken, dataOrNA, setPropertyByPath } from "../utils";
import { showNotification } from "../notifications";
import { getDateFormat, getFormattedDate, matchStart, timepickerMeridian } from "../date";
import * as Ladda from 'ladda'
import { isRTL } from "../locale";
import { handleTransferResponse } from "./external_deliveries";

export const openYangoModal = async (taskIds, visitIds, onSuccess = () => { window.location.reload() }, onFailure = () => {}) => {
  const modalContainer = $('#modal-container')
  const loader = $('.data-preloader')
  loader.removeClass('d-none')
  const url = `/yango/delivery_modal?task_ids=${dataOrNA(taskIds)}&visit_ids=${dataOrNA(visitIds)}.json`
  await fetch(url,
    {
      headers: { 'X-CSRF-TOKEN': csrfToken() },
      method: 'POST'
    }
  ).then(res => {
    if (res.status === 200 || res.status === 422) {
      if (res.status === 422) {
        res.json().then(data => { showNotification({ message: data.errors.join(", "), type: 'danger' }) })
      } else {
        res.text().then((data) => {
          modalContainer.html(data)
          $('.interval-select').select2({
            dir: i18n.t('direction'),
            width: '100%',
            matcher: (params, data) => matchStart(params, data)
          });

          ['city', 'street', 'number', 'recipient', 'phone'].forEach(field => {
            $(`#yango-${field}-input`).on('change', (e) => { $(`#yango-${field}`).text(e.currentTarget.value) })
          })


          $('.yango-express-datepicker').datepicker({
            format: getDateFormat(),
            language: i18n.t('locale'),
            rtl: isRTL(),
            orientation: 'bottom',
            startDate: getFormattedDate(),
            defaultDate: getFormattedDate(),
            autoclose: true,
            todayHighlight: true,
          })

          $('.yango-express-timepicker').timepicker({
            minuteStep: 15,
            showMeridian: timepickerMeridian(),
            icons: {
              up: 'ki ki-arrow-up',
              down: 'ki ki-arrow-down'
            }
          })

          $('.express-time-type-select').on('change', (e) => {
            $('.yango-express-datetime-section').toggleClass('d-none', e.currentTarget.value === 'asap')
          })

          checkSubmitAvailability()

          $('.yango-types-toggle').on('click', (e) => {
            const yangoType = e.currentTarget.dataset.yangoType
            $('#yango_delivery_type').val(yangoType)
            checkSubmitAvailability()
            updatePrices(taskIds, visitIds)
          })

          $('.express-type-select, .interval-select').on('change', () => { updatePrices(taskIds, visitIds) })

          updatePrices(taskIds, visitIds)
          handleTransferResponse(modalContainer.find('form'), () => { $('#yango-modal').modal('hide'); onSuccess() }, onFailure)

          modalContainer.find('#yango-modal').modal('show')
          modalContainer.find('#yango-source-modal').on('hidden.bs.modal', () => {
            $('body').addClass('modal-open')
            updatePrices(taskIds, visitIds)
          })
        })
      }
    } else {
      showNotification({ message: i18n.t('shared.something_went_wrong'), type: 'danger' })
    }
    Ladda.stopAll()
    loader.addClass('d-none')
  })
}

const updatePrices = async (taskIds, visitIds) => {
  const url = `/yango/estimate_delivery?task_ids=${dataOrNA(taskIds)}&visit_ids=${dataOrNA(visitIds)}`

  $(`.yango-prices-spinner`).removeClass('d-none')
  $(`#yango-prices`).html('')

  const body = {}
  $('#yango-form').serializeArray().forEach(field => { setPropertyByPath(body, field.name, field.value) });

  await fetch(url,{
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken()
      },
      method: 'POST',
      body: JSON.stringify(body)
    }
  ).then(res => res.json()).then(data => {
    $('#yango-prices').html(data.map(row => priceRow(row)).join(''))
    $(`.yango-prices-spinner`).addClass('d-none')
  })
}

const priceRow = (rowData) => (`
  <div class="row align-items-center">
      <div class="col-6 font-size-h5">
          ${rowData.task_ids.length > 1 ? i18n.t('models.task.tasks') : i18n.t('models.task.task') } ${rowData.task_ids.join(', ')}:
      </div>
      <div class="col-6 font-size-h5">
          ${rowData.failed ? i18n.t('models.task.status_enum.FAILED') : `${rowData.price} ${rowData.currency}`}
      </div>
  </div>`
)

const checkSubmitAvailability = () => {
  const submitButton = $('#yango-submit')
  const modalFooter = $('#yango-modal .modal-footer')
  const estimationSection = $('#yango-estimation-section')
  const deliveryType = $('#yango_delivery_type').val()
  const selectedInterval = $('.interval-select').val()

  let disableCondition = null

  if (deliveryType === 'express') {
    disableCondition = $('#yango-task-ids').val().split(',').length > 15
    $('#express-limit-error').toggleClass('d-none', !disableCondition)
    $('#sdd-no-interval-error').addClass('d-none')
  } else if (deliveryType === 'sdd') {
    disableCondition = !selectedInterval
    $('#sdd-no-interval-error').toggleClass('d-none', !disableCondition)
    $('#express-limit-error').addClass('d-none')
  }
  submitButton.prop('disabled', disableCondition).toggleClass('d-none', disableCondition)
  modalFooter.toggleClass('d-none', disableCondition)
  estimationSection.toggleClass('d-none', disableCondition)
}
