import { isRTL } from './locale'

export function showNotification ({ message, type = 'success' }) {
  $.notify({
    message: message
  }, {
    type: type,
    placement: {
      from: 'bottom',
      align: isRTL() ? 'left' : 'right'
    }
  })
  const closeButtons = $('.alert > button.close')
  for (let i = 0; i < closeButtons.length; i++) {
    closeButtons[i].style.fontSize = '0'
    closeButtons[i].style.top = '50%'
    closeButtons[i].style.transform = 'translateY(-50%)'
  }
  if (isRTL()) {
    for (let i = 0; i < closeButtons.length; i++) {
      closeButtons[i].style.removeProperty('right')
      closeButtons[i].style.left = '10px'
    }
  }
}
