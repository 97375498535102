import { i18n } from "../helpers/i18n";

export const ISRAEL_CITIES_URL = '/israel_cities'
export const APP_HOST = 'https://members.lionwheel.com'
export const STRIPE_PUB_KEY = 'pk_live_51JDb5qClWmUXHuCwu7IMElXsZOWtdzMTmkQkxshRhMIUBtaNtcO8y2C5ZeKOAzjUQOSn0CJLn23YJAOrzco4orDr00rpn4tuA6'
export const EMPTY_LINE = '.'
export const STATUSES = {
  UNASSIGNED: {
    color: '#e7505a',
    name: () => (i18n.t('models.task.status_enum.UNASSIGNED'))
  },
  ASSIGNED: {
    color: '#c49f47',
    name: () => (i18n.t('models.task.status_enum.ASSIGNED'))
  },
  ACTIVE: {
    color: '#32c5d2',
    name: () => (i18n.t('models.task.status_enum.ACTIVE'))
  },
  COMPLETED: {
    color: '#3598dc',
    name: () => (i18n.t('models.task.status_enum.COMPLETED'))
  },
  CANCELED: {
    color: '#e7ecf1',
    name: () => (i18n.t('models.task.status_enum.CANCELED'))
  },
  ROUNDTRIP_DELIVERED: {
    color: '#800080',
    name: () => (i18n.t('models.task.status_enum.ROUNDTRIP_DELIVERED'))
  },
  FAILED: {
    color: '#6d3030',
    name: () => (i18n.t('models.task.status_enum.FAILED'))
  },
  FINAL_FAILED: {
    color: '#000000',
    name: () => (i18n.t('models.task.status_enum.FINAL_FAILED'))
  },
  IN_TRANSFER: {
    color: '#f70268',
    name: () => (i18n.t('models.task.status_enum.IN_TRANSFER'))
  },
  IN_INVENTORY: {
    color: '#bf5a08bf',
    name: () => (i18n.t('models.task.status_enum.IN_INVENTORY'))
  },
  OUT_INVENTORY: {
    color: '#ffa800',
    name: () => (i18n.t('models.task.status_enum.OUT_INVENTORY'))
  },
}

export const RATES = {
  1: {
    color: '#d10000',
    name: () => (i18n.t('views.task_review.rating_enum.1'))
  },
  2: {
    color: '#ed7014',
    name: () => (i18n.t('views.task_review.rating_enum.2'))
  },
  3: {
    color: '#ffff30',
    name: () => (i18n.t('views.task_review.rating_enum.3'))
  },
  4: {
    color: '#a4d1a2',
    name: () => (i18n.t('views.task_review.rating_enum.4'))
  },
  5: {
    color: '#06c258',
    name: () => (i18n.t('views.task_review.rating_enum.5'))
  }
}

export const ARROWHEADS_OPTIONS = {
  fill: true,
  yawn: 40,
  opacity: 0.5
}

export const POLYLINE_OPTIONS = {
  opacity: 0.5,
  weight: 7
}

export const isDev = () => {
  try {
    return process.env.NODE_ENV === 'development'
  } catch {
    return false
  }
}
