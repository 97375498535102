import { showNotification } from "../notifications";

export const handleTransferResponse = ($el, onSuccess = () => {}, onFailure = () => {}) => {
  $el.on('ajax:success', (e) => {
    const data = e.detail[0]
    showNotification({ message: data.message })
    onSuccess()
  }).on('ajax:error', (e) => {
    const data = e.detail[0]
    showNotification({ message: data.message, type: 'danger' })
    onFailure()
  })
}
