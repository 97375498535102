import { i18n } from "../i18n";
import { csrfToken, dataOrNA, setPropertyByPath } from "../utils";
import { showNotification } from "../notifications";
import { initMap } from "../../services/map";
import * as Ladda from "ladda";
import { fitBounds, getAllMapMarkers } from "../leaflet";
import { matchStart } from "../date";
import { handleTransferResponse } from "./external_deliveries";

export const openWoltModal = async (taskIds, visitIds, onSuccess = () => { window.location.reload() }, onFailure = () => {}) => {
  const modalContainer = $('#modal-container')
  const loader = $('.data-preloader')
  loader.removeClass('d-none')
  const url = `/wolt/delivery_modal?task_ids=${dataOrNA(taskIds)}&visit_ids=${dataOrNA(visitIds)}.json`
  await fetch(url,
    {
      headers: { 'X-CSRF-TOKEN': csrfToken() },
      method: 'POST'
    }
  ).then(res => {
    if (res.status === 200 || res.status === 422) {
      if (res.status === 422) {
        res.json().then(data => { showNotification({ message: data.errors.join(', '), type: 'danger' }) })
      } else {
        res.text().then((data) => {
          modalContainer.html(data)
          const mainModal = modalContainer.find("#wolt-modal")
          mainModal.modal('show')

          const map = initMap(document.getElementById('wolt-map'), () => {})
          setTimeout(() => { map.invalidateSize() }, 200)

          updatePrices(taskIds, visitIds, (data) => { drawMarkers(map, data) })

          $('.wolt-types-toggle').on('click', (e) => {
            const woltType = e.currentTarget.dataset.type
            $('#wolt_delivery_type').val(woltType)
            updatePrices(taskIds, visitIds)
          })

          $('.scheduled-time-select').on('click', () => {
            updatePrices(taskIds, visitIds)
          });

          ['city', 'street', 'number', 'recipient', 'phone'].forEach(field => {
            $(`#wolt-${field}-input`).on('change', (e) => { $(`#wolt-${field}`).text(e.currentTarget.value) })
          })

          $('#wolt-location').select2({
            dir: i18n.t('direction'),
            width: '100%',
            matcher: (params, data) => matchStart(params, data)
          }).on('change', (e) => {
            const option = e.currentTarget.options[e.currentTarget.selectedIndex];
            ['city', 'street', 'number', 'floor', 'apartment', 'recipient', 'phone', 'notes'].forEach(field => {
              $(`#wolt-${field}-input`).val(option.dataset[field])
            })
          })

          modalContainer.find('#wolt-source-modal').on('hidden.bs.modal', () => {
            $('body').addClass('modal-open')
            updatePrices(taskIds, visitIds, (data) => { drawMarkers(map, data) })
          })

          handleTransferResponse(modalContainer.find('form'), () => { mainModal.modal('hide'); onSuccess() }, onFailure)
        })
      }

    } else {
      showNotification({ message: i18n.t('shared.something_went_wrong'), type: 'danger' })
    }
    Ladda.stopAll()
    loader.addClass('d-none')
  })
}

const updatePrices = async (taskIds, visitIds, onSuccess = () => {}) => {
  const url = `/wolt/price_estimation?task_ids=${dataOrNA(taskIds)}&visit_ids=${dataOrNA(visitIds)}`

  $('.wolt-prices-spinner').removeClass('d-none')
  $(`#wolt-prices`).html('')

  const body = {}
  $('#wolt-form').serializeArray().forEach(field => { setPropertyByPath(body, field.name, field.value) });

  await fetch(url,{
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken()
      },
      method: 'POST',
      body: JSON.stringify(body)
    }
  ).then(res => res.json()).then(data => {
    if (data.failed) {
      showNotification({ message: i18n.t('shared.something_went_wrong'), type: 'danger' })
      $('#wolt-submit').prop('disabled', true)
    } else {
      $('#wolt-submit').prop('disabled', false)
      onSuccess(data)
    }

    $('#wolt-prices').html(data.tasks.map(row => priceRow(row)).join(''))
    $('.wolt-prices-spinner').addClass('d-none')
  })
}

const priceRow = (rowData) => (`
  <div class="row align-items-center">
      <div class="col-6 font-size-h5">
        ${i18n.t('models.task.task') } ${rowData.id}:
      </div>
      <div class="col-6 font-size-h5">
        ${rowData.error ? rowData.error : `<span class="font-weight-bolder">${rowData.price} ${rowData.currency_symbol}</span>`}
      </div>
  </div>`
)

const drawMarkers = (map, data) => {
  getAllMapMarkers(map).forEach((m) => { map.removeLayer(m) })

  const destinationIcon = L.divIcon({
    className: 'blue-number-icon',
    iconSize: [29, 47],
    iconAnchor: [15, 47]
  })

  const sourceIcon = L.divIcon({
    className: 'red-number-icon',
    iconSize: [29, 47],
    iconAnchor: [15, 47]
  })

  L.marker([data.source.lat, data.source.lon], { icon: sourceIcon }).addTo(map)

  data.tasks.forEach((t) => {
    L.marker([t.dropoff.lat, t.dropoff.lon], { icon: destinationIcon }).addTo(map)
  })

  fitBounds(map)
}
