export const saveDailyVisitsSchedule = (e, inputName) => {
  $(window).unbind('beforeunload')
  const visitRows = $('.visit-row')
  const form = $('.daily-route-form')
  const visits = {}

  for (let i = 0; i < visitRows.length; i++) {
    visits[i] = visitRows[i].getAttribute('visit-id')
  }
  form.find(inputName).val(JSON.stringify(visits))
  form.attr('action', e.currentTarget.dataset.url).submit()
}

export const saveDailyRoutePlanVisitsSchedule = (e, inputName, pendingVisitsChanged) => {
  $(window).unbind('beforeunload');
  const form = $('.daily-route-form');
  const visits = {};
  const visitsRowKlass = pendingVisitsChanged ? '.pending-deliveries-row' : '.all-deliveries-row';
  const visitsRows = $(visitsRowKlass);
  const allVisits = [];

  visitsRows.each((_i, row) => allVisits.push(row.getAttribute('visit-id')));
  allVisits.forEach((val, i) => visits[i] = val);
  form.find(inputName).val(JSON.stringify(visits));
  form.attr('action', e.currentTarget.dataset.url).submit();
}

export const fetchCurrentLocations = async (url) => {
  const res = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  })
  if (res.status === 200) {
    return res.json()
  } else if (res.status === 401) {
    return 'unauthorized'
  } else {
    return false
  }
}
